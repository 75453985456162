<script>
  import { onMount } from "svelte";
  import FartInfo from "./FartInfo.svelte";
  export let farts;
  export let selectedFartIndex;
  export let fartSelected;

  document.addEventListener("keydown", function (e) {
    if (e.key === "ArrowLeft") {
      document.querySelector("button[rel=prev]").click();
    } else if (e.key === "ArrowRight") {
      document.querySelector("button[rel=next]").click();
    }
  });

  function fartClicked(e) {
    const fartNode = this;
    const i = [...fartNode.parentNode.childNodes].indexOf(fartNode);
    fartSelected(i);
    scroll(i);
    e.preventDefault();
  }

  function navNext() {
    if (selectedFartIndex < farts.length - 1) {
      const i = selectedFartIndex + 1;
      fartSelected(i);
      scroll(i);
    }
  }

  function navPrevious() {
    if (selectedFartIndex > 0) {
      const i = selectedFartIndex - 1;
      fartSelected(i);
      scroll(i);
    }
  }

  function scroll(i) {
    document.querySelector("#list-container").scrollTo({
      top: i * 60,
      left: 0,
      behavior: "smooth",
    });
  }
</script>

<nav>
  <button rel="prev" disabled={selectedFartIndex <= 0} on:click={navPrevious}
    >&lt; Previous</button
  >
  <button
    rel="next"
    disabled={farts.length === 0 || selectedFartIndex === farts.length}
    on:click={navNext}>Next &gt;</button
  >
  <div id="list-container">
    <ol>
      {#each farts as fart, idx}
        <li class:selected={idx === selectedFartIndex} on:click={fartClicked}>
          <FartInfo {fart} />
        </li>
      {:else}
        <li style="list-style-type: none;"><h1>Loading</h1></li>
      {/each}
    </ol>
  </div>
</nav>

<style>
  #list-container {
    position: absolute;
    top: 208px;
    height: 100vh;
    position: relative;
    overflow-y: scroll;
  }
  nav {
    text-align: center;
    margin: 0 auto;
    width: 600px;
    height: 100px;
  }
  ol {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
  }
  li {
    cursor: pointer;
    color: #999;
    text-align: center;
    list-style-position: inside;
    height: 60px;
  }
  li.selected {
    cursor: default;
    font-size: 150%;
    color: #000;
    margin-bottom: 32px;
    border-bottom: 1px solid #000;
  }
  button {
    width: 130px;
  }
</style>
