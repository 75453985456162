<script>
  import { onMount, afterUpdate, beforeUpdate } from "svelte";
  import WaveSurfer from "wavesurfer.js";
  import RegionsPlugin from "wavesurfer.js/src/plugin/regions";

  export let fart;
  export let onSave;

  let trimmedFartUrl;
  let wavesurfer;
  let isChanged = false;
  // const fartsUrl = "http://localhost:8000/api/farts/TELEGRAM-1001385689014";
  const fartsUrl =
    "https://webapp.fartbot.net/api/farts/TELEGRAM-1001385689014";

  const regionOptions = (fart) => {
    const endTime = (fart.duration / 1000) * 0.85;
    const startTime = (fart.duration / 1000) * 0.1;
    console.log(endTime);

    return {
      handleStyle: {
        left: {
          width: "6px",
          backgroundColor: "yellow",
        },
        right: {
          width: "6px",
          backgroundColor: "yellow",
        },
      },
      start: startTime,
      end: endTime,
      loop: false,
      color: "purple",
    };
  };

  onMount(async () => {
    wavesurfer = WaveSurfer.create({
      container: document.querySelector("#waveform"),
      cursorColor: "#0e692f",
      waveColor: "#000",
      progressColor: "#0e692f",
      splitChannels: true,
      splitChannelsOptions: {
        filterChannels: [1],
        overlay: true,
      },
      backend: "MediaElement",
      barWidth: 4,
      barHeight: 2, // the height of the wave
      barGap: null, // the optional spacing between bars of the wave, if not provided will be calculated in legacy format
      plugins: [
        RegionsPlugin.create({
          maxRegions: 1,
          regions: [],
          dragSelection: {
            slop: 5,
          },
        }),
      ],
    });

    wavesurfer.on("error", function (e) {
      console.error(e);
    });

    wavesurfer.on("region-update-end", function (e) {
      isChanged = true;
      return true;
    });

    wavesurfer.on("ready", function (e) {
      wavesurfer.clearRegions();
      isChanged = false;
    });

    document.addEventListener("keydown", function (e) {
      if (e.key == "Escape") {
        wavesurfer.clearRegions();
        isChanged = false;
      } else if (e.key == " ") {
        e.preventDefault();
        play();
      }
    });
  });

  afterUpdate(() => {
    if (fart && trimmedFartUrl !== fart.url) {
      wavesurfer.load(fart.url);
      trimmedFartUrl = fart.url;
    }
  });

  const saveClicked = function (e) {
    const url = fartsUrl + `/${fart.fat16}/trim`;
    const region = Object.values(wavesurfer.regions.list)[0];

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        start_time: region.start,
        end_time: region.end,
      }),
    })
      .then((response) => response.json())
      .then(({ data }) => {
        wavesurfer.empty();
        wavesurfer.load(data.url);
        onSave(data);
      });
  };

  const play = function (e) {
    const region = Object.values(wavesurfer.regions.list)[0];
    if (wavesurfer.isPlaying()) {
      wavesurfer.stop();
    }
    if (region) {
      region.play();
    } else {
      wavesurfer.play();
    }
  };
</script>

<article id="waveform" />
<br />
<button on:click={play}>Play</button>
<button disabled={!isChanged} on:click={saveClicked}>Save</button>

<style>
  #waveform {
    border: 0px solid #000;
    max-width: 1000px;
    margin: 0 auto;
  }
  button {
    width: 130px;
  }
</style>
