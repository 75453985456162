<script>
  export let fart;

  function fartDate(unixtime) {
    const d = new Date();
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    d.setTime(unixtime * 1000);
    return d.toLocaleString("nl-NL", options);
  }

  function farter(userId) {
    if (userId === 10753175) {
      return "Bas";
    } else {
      return "Jeroen";
    }
  }
</script>

{farter(fart.user_id)}
{fartDate(fart.created_at)} |
{fart.duration / 1000}s
