<script>
  import { onMount } from "svelte";
  import Editor from "./Editor.svelte";
  import Nav from "./Nav.svelte";

  // const fartsUrl = "http://localhost:8000/api/farts/TELEGRAM-1001385689014";
  const fartsUrl =
    "https://webapp.fartbot.net/api/farts/TELEGRAM-1001385689014";

  let farts = [];
  let selectedFartIndex = -1;

  onMount(async () => {
    const response = await fetch(fartsUrl);
    const data = await response.json();
    farts = data.farts
      .sort((a, b) => a.created_at - b.created_at)
      .map((a) => {
        a.url = a.url.replace(".mp3", ".wav");
        return a;
      });

    selectedFartIndex = 0;
  });

  function updateFart(fart) {
    farts[selectedFartIndex] = fart;
  }

  function fartSelected(index) {
    selectedFartIndex = index;
  }
</script>

<main>
  <div id="editor-container">
    <Editor fart={farts[selectedFartIndex]} onSave={updateFart} />
  </div>
  <Nav {farts} {selectedFartIndex} {fartSelected} />
</main>

<style>
  main {
    padding: 1em;
    margin: 0 auto;
  }
  #editor-container {
    position: absolute;
    top: 80px;
    left: 20px;
    right: 20px;
    text-align: center;
  }
</style>
